<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">Become A Runner</h1>
      <h3 class="container ls-page-header-subtitle">
        Join the team as a Runner to help deliver goods and services for
        clients.
      </h3>
    </div>

    <div class="ps-section__content ls-page-content"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "RegisterRunner",
  mounted() {
    this.getRunners();
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getRunners() {
      axios.get("v1/getrunner/runners").then((response) => {
        //console.log("Get Runner : " + JSON.stringify(response.data.data));
      });
    },
  },
};
</script>
